<template>
  <div class="complete">
    <h1 class="complete__title">편지를 완성했어요.</h1>
    <p class="complete__subtitle">완성된 편지를 이미지로 전달해보세요.</p>
    <p class="complete__text">SNS 공유를 통해 효도 인증도 해보세요</p>
    <img
      src="@/assets/complete_image.svg"
      alt="complete_page_image"
      class="complete_page_image"
    />

    <a class="button" href="/envelope.png" download="envelope">
      편지 봉투 이미지 다운받기
      <p class="sns__text">(SNS 공유용 이미지)</p>
    </a>
    <div class="button" @click="LetterDownload()">편지 내용 다운받기</div>
    <div class="button2" @click="Alter()">편지 수정하기</div>

    <ins
      class="adsbygoogle"
      style="
        display: inline-block;
        width: 300px;
        height: 50px;
        margin-top: 20px;
      "
      data-ad-client="ca-pub-1919598055512436"
      data-ad-slot="5598385343"
    ></ins>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "complete",
  mounted() {
    (adsbygoogle = window.adsbygoogle || []).push({});
  },
  methods: {
    Alter() {
      this.$router.push("/write");
    },
    LetterDownload() {
      try {
        if (this.$store.state.base64 == "") {
          alert("편지를 작성해주세요.");
          this.$router.push("/write");
        } else {
          var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
          if (
            varUA.indexOf("iphone") > -1 ||
            varUA.indexOf("ipad") > -1 ||
            varUA.indexOf("ipod") > -1
          ) {
            //IOS
            if (/CriOS/i.test(navigator.userAgent)) {
              alert(
                "아이폰 크롬 브라우저는 현재 지원하지 않습니다. 사파리를 이용해주세요."
              );
              return true;
            } else if (
              /kakaotalk/i.test(navigator.userAgent) ||
              /naver/i.test(navigator.userAgent) ||
              /instagram/i.test(navigator.userAgent) ||
              /messenger/i.test(navigator.userAgent)
            ) {
              this.$router.push("/image");
              return true;
            } else {
              var link = document.createElement("a");

              link.setAttribute("href", this.$store.state.base64);
              link.setAttribute("type", "image/png");
              link.setAttribute("download", "letter.png");
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          } else {
            var link = document.createElement("a");

            link.setAttribute("href", this.$store.state.base64);
            link.setAttribute("type", "image/png");
            link.setAttribute("download", "letter.png");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      } catch (e) {}
    },
  },
};
</script>
<style scoped>
.complete {
  width: 100%;
  min-height: 100%;

  background-color: white;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  padding: 40px 30px;
}
.complete__subtitle {
  font-family: "GmarketSansTTF";
  font-style: normal;
  font-size: 20px;

  margin: 12px 0px;

  word-break: keep-all;
}
.complete__title {
  font-family: "GmarketSansTTFBold";
  font-style: normal;
  font-weight: bold;
  font-size: 34px;

  margin: 12px 0px;

  word-break: keep-all;
}
.complete_page_image {
  width: 76%;
  min-width: 300px;

  margin: 40px 0px;
}
.complete__text {
  max-width: 420px;

  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;

  font-family: "GmarketSansTTFLight";

  color: #666666;

  word-break: keep-all;
}
.button {
  width: 330px;
  max-height: 56px;
  height: 56px;
  min-height: 56px;

  background-color: #ea5532;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  text-decoration: none;
  font-size: 16px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  color: white;

  border-radius: 6px;

  font-family: "Noto Sans";

  cursor: pointer;

  margin-top: 20px;
}
.sns__text {
  max-width: 420px;

  font-style: normal;
  font-weight: 300;
  font-size: 10px;

  font-family: "GmarketSansTTFLight";

  color: white;

  word-break: keep-all;

  margin: 0px;
}
.button2 {
  width: 330px;
  height: 56px;
  border-radius: 10px;
  border: 1px solid #ea5532;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  background-color: white;

  color: #ea5532;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  margin-top: 20px;
}

@media screen and (max-width: 440px) {
  .button,
  .button2 {
    width: calc(100% - 18px);
    height: 50px;
    margin: 20px auto 0px auto;
  }
}
</style>
